/*
|--------------------------------------------------------------------------
| Media Query Manager
|--------------------------------------------------------------------------
*/

@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: $xs -1px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (max-width: $xs) and (min-width: $sm - 1px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (max-width: $sm) and (min-width: $md - 1px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (max-width: $md) and (min-width: $lg - 1px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (max-width: $lg) and (min-width: $xxl - 1px) {
      @content;
    }
  }
  @if $breakpoint == xxl {
    @media only screen and (max-width: $xxl) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
  }
}
