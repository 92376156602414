/*
|--------------------------------------------------------------------------
| Spacing
|--------------------------------------------------------------------------
*/

@each $spacing, $space in $spaces {
  // Margin
  .margin-bottom-#{$spacing} {
    margin-bottom: #{$space} !important;
  }
  .margin-top-#{$spacing} {
    margin-top: #{$space} !important;
  }

  // Padding
  .padding-bottom-#{$spacing} {
    padding-bottom: #{$space} !important;
  }
  .padding-top-#{$spacing} {
    padding-top: #{$space} !important;
  }
}

/*
  |--------------------------------------------------------------------------
  | Typography Utilities
  |--------------------------------------------------------------------------
  */

// alignments
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

// Modifiers
.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 600;
}

.text-uppercase {
  text-transform: uppercase;
}

/*
  |--------------------------------------------------------------------------
  | Mobile - Desktop
  |--------------------------------------------------------------------------
  */

.mobile {
  display: inherit;

  @media screen and (min-width: $md) {
    display: none;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: $md) {
    display: inherit;
  }
}

/*
  |--------------------------------------------------------------------------
  | Positioning
  |--------------------------------------------------------------------------
  */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/*
  |--------------------------------------------------------------------------
  | Arrows
  |--------------------------------------------------------------------------
  */
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  &-white {
    border: solid white;
  }

  &-big {
    padding: 6px;
  }

  &-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
