.btn {
  &,
  &:link,
  &:visited {
    padding: 1.5rem 3rem;
    font-size: 1rem;
    letter-spacing: 0.25em;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: $radius;
    display: inline-block;
    transition: all 1s ease;
    overflow: hidden;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &--black {
    color: $white;
    background-color: $black;

    &:hover {
      background-color: $grey;
    }
  }

  &--white {
    color: $black;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $grey;
    }
  }

  &--sm {
    padding: 0.6rem 1rem;
  }

  &--lg {
    padding: 2rem 3rem;
  }

  &--tight {
    letter-spacing: 0;
  }

  &--full {
    width: 100%;
  }
}

$cross-fade-border-width: 2px;
$cross-fade-border-color: black;
$sec-cross-fade-border-color: black;
$cross-fade-button-padding: 16px 28px;
$cross-fade-text-color: black;
$sec-cross-fade-text-color: white;
$cross-fade-border-radius: 0px;
$cross-fade-background: white;
$sec-cross-fade-background: #666;
$cross-fade-transition-time: 0.75s;

.cross-fade {
  position: relative;
  overflow: hidden;
  transition: color $cross-fade-transition-time ease-in-out;
  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -25%;
    width: 150%;
    height: 100%;
    background: rgba($sec-cross-fade-background, 0.5);
    transition: transform $cross-fade-transition-time ease-in-out;
    z-index: -1;
  }

  &:before {
    transform: translate3d(100%, 0, 0) skew(20deg);
    transform-origin: 0 0;
  }

  &:after {
    transform: translate3d(-100%, 0, 0) skew(20deg);
    transform-origin: 100% 100%;
  }

  &:hover {
    color: $sec-cross-fade-text-color;
    &:before,
    &:after {
      transform: translate3d(0, 0, 0) skew(20deg);
    }
  }
}

.slider-navigation {
  display: flex;
  justify-content: space-evenly;
  width: 100px;
}

.previous,
.next {
  display: inline-block;
  cursor: pointer;
  background-color: black;
  color: white;
  font-size: 14px;
  border-radius: 50%;
  border: none;
  padding: 10px 15px;
}
