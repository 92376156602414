/*
|--------------------------------------------------------------------------
| Import Fonts
|--------------------------------------------------------------------------
*/

// @import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900");
// @import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700");

/*
|--------------------------------------------------------------------------
| General
|--------------------------------------------------------------------------
*/
body {
  font-family: $base-font;
  font-weight: 400;
  line-height: 1.7;
  color: $grey;
  font-size: $base-fs;
}

b,
strong {
  font-weight: 700;
}

a {
  color: $grey;
}

/*
|--------------------------------------------------------------------------
| Headings
|--------------------------------------------------------------------------
*/
h1,
.h1 {
  font-size: 3.5rem;
  color: $black;
  margin-bottom: 1rem;
}

h2,
.h2 {
  font-size: 2.5rem;
  color: $black;
}

h3,
.h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-size: 1.4rem;
  text-transform: uppercase;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: $heading-font;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
