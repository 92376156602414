/*
|--------------------------------------------------------------------------
| Import modules
|--------------------------------------------------------------------------
*/

@import "../node_modules/swiper/swiper-bundle.css";
// @import "../node_modules/normalize-scss/sass/normalize/";

/*
|--------------------------------------------------------------------------
| Abstracts
|--------------------------------------------------------------------------
*/

@import "scss/abstracts/variables";
@import "scss/abstracts/mixins";

/*
|--------------------------------------------------------------------------
| Base
|--------------------------------------------------------------------------
*/

@import "scss/base/base";
@import "scss/base/typography";
@import "scss/base/utilities";

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/

@import "scss/components/buttons";
@import "scss/components/form";
@import "scss/components/links";
@import "scss/components/loader";
@import "scss/components/swiper-slider";

/*
|--------------------------------------------------------------------------
| Layout
|--------------------------------------------------------------------------
*/

@import "scss/layout/page";
