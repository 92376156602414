.form {
  h2 {
    text-align: center;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  textarea {
    border-radius: $radius;
    width: 100%;
    border: 1px solid $light-grey;
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    background: $white;
    font-size: 1.2rem;
    color: $black;
    -webkit-appearance: none;
  }

  textarea {
    height: 15rem;
  }
}
