* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  // This defines what 1rem is

  // 1 rem = 8px;
  font-size: 54%;

  @include respond($lg) {
    // 1 rem = 10px;
    font-size: 62.5%;
  }
}

body {
  position: relative;
  background-color: $white;
  overflow-x: hidden;
  @media screen and (min-width: $md) {
    overflow-y: hidden; /* Hide scrollbars */
  }
}

main {
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 1rem 0 2rem 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

::selection {
  background-color: $grey;
  color: $white;
}
