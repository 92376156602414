.page {
  .content {
    margin: 2rem 3rem;

    &:last-child {
      margin-bottom: 100px;
    }

    h2 {
      margin-top: 4rem;
    }

    h3 {
      margin-bottom: 2rem;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: $sm) {
    .content {
      margin: 4rem 10rem;
    }
  }

  @media screen and (min-width: $md) {
    height: 100%;

    .content-wrapper {
      height: 100%;
      overflow-y: scroll;
      scroll-snap-type: y proximity;
      // scroll-snap-type: y mandatory;
      scroll-behavior: smooth;

      .section {
        scroll-snap-align: center;
      }
    }

    .content {
      margin: 0rem 3rem;
    }

    .content-block {
      // height: calc(100vh - 150px);

      ul {
        list-style-type: circle;
        margin-left: 2rem;
      }
    }
  }

  @media screen and (min-width: $lg) {
    .content {
      margin: 0rem 6rem;
    }
  }

  @media screen and (min-width: 1800px) {
    .content {
      margin: 0rem 12rem;
    }
  }
}
