.loader {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  //   height: 50px;
  width: 100%;

  &__inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
    color: $white;
    padding: 0 100px;
    text-align: center;

    label {
      font-size: 20px;
      opacity: 0;
      display: inline-block;

      &:nth-child(6) {
        animation: loading 3s infinite ease-in-out;
      }

      &:nth-child(5) {
        animation: loading 3s 100ms infinite ease-in-out;
      }

      &:nth-child(4) {
        animation: loading 3s 200ms infinite ease-in-out;
      }

      &:nth-child(3) {
        animation: loading 3s 300ms infinite ease-in-out;
      }

      &:nth-child(2) {
        animation: loading 3s 400ms infinite ease-in-out;
      }

      &:nth-child(1) {
        animation: loading 3s 500ms infinite ease-in-out;
      }
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}
