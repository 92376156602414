/*
|--------------------------------------------------------------------------
| Colours
|--------------------------------------------------------------------------
*/

$white: #fff;
$black: #303030;
$grey: lighten($black, 30);
$light-grey: lighten($black, 70);

/*
|--------------------------------------------------------------------------
| Layout
|--------------------------------------------------------------------------
*/
$nav-height: 70px;
$footer-height: 80px;

/*
|--------------------------------------------------------------------------
| Geometry
|--------------------------------------------------------------------------
*/
$radius: 0;

/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
*/
$base-font: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-fs: 1.3rem;

$heading-font: "Oswald", Arial, sans-serif;

/*
|--------------------------------------------------------------------------
| Spacing
|--------------------------------------------------------------------------
*/
$spacing-small: 2rem;
$spacing-medium: 4rem;
$spacing-big: 6rem;
$spacing-huge: 8rem;

$spaces: (
  "small": $spacing-small,
  "medium": $spacing-medium,
  "big": $spacing-big,
  "huge": $spacing-huge,
);

/*
|--------------------------------------------------------------------------
| Media Queries
|--------------------------------------------------------------------------
*/
$nav-breakpoint: 820px;

// $xs: 30em; // 480px
// $sm: 37.5em; // 600px
// $md: 56.25em; // 900px
// $lg: 75em; // 1200px
// $xl: 93.75em; // 1500px
// $xxl: 112.5em; // 1800px

$xs: 400px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

$breakpoints: (
  "xs": $xs,
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
);
