a {
  transition: all 0.5s ease;
  text-decoration: none;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $black;
  }
}
